<template>
  <div class="loginPage">
    <div class="loginL">
      <img class="loginMain" src="../../assets/img/login/loginMain.png" />
    </div>
    <div class="loginR">
      <img class="loginMain1" src="../../assets/img/login/loginMain1.png" />
      <div class="loginPanel">
        <img class="loginHeadPic" src="../../assets/img/layout/headPic.png" />
        <div class="loginTitle">花生宠收银系统</div>
        <div class="inputLine">
          <img class="loginIcon" src="../../assets/img/login/loginUser.png" />
          <el-input v-model.trim="userName" placeholder="请输入用户名"></el-input>
        </div>
        <div class="inputLine">
          <img class="loginIcon" src="../../assets/img/login/loginPwd.png" />
          <el-input type="password" v-model.trim="userPwd" @keyup.enter.native="login" placeholder="请输入密码"></el-input>
        </div>
        <div class="btnLine">
          <div class="submit" @click="login">登录</div>
          <div class="submit1" @click="login1">下载客户端</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import menuListRoute from './menu.json';
export default {
  data() {
    return {
      userName: '',
      userPwd: '',
      systemId: '654321',
      menuListRoute: menuListRoute.children,
      downloading: false, //跟踪文件是否正在下载
    }
  },
  computed: {

  },
  created() {
    if (!this.$public.isNull(this.$store.state.token)) {
      this.$router.push({ path: '/login' })
    }
  },
  mounted() {

  },
  methods: {
    // 下载客户端
    login1(){
			if (this.downloading) return;
			this.downloading = true;
			const link = document.createElement("a");
			link.href = 'https://huashengchong.oss-cn-beijing.aliyuncs.com/tools/hscClient.exe';
			link.setAttribute("download", "hscClient.exe");
			document.body.appendChild(link);
			link.click();
			setTimeout(() => {
				document.body.removeChild(link);
				this.downloading = false;
			}, 10000); // 设置节流时间，例如1秒

    },
    login() {
      let params = {
        userName: this.userName,
        userPwd: this.userPwd,
        systemId: this.systemId,
      }
      let loading = this.$loading()
      this.$http.post("/cms/StaffInfo/UserLoginCompany", params).then(async (res) => {
        loading.close()
        if (res.code === 0) {
          this.$store.commit("setUserInfo", res.data.model)
          this.$store.commit("setShopInfo", res.data.shopInfo)
          this.$store.commit("setToken", res.data.token)
          this.$store.commit("mendian/setModule", "消费")
          this.$store.commit("mendian/setUserInfo", {})
          // this.$router.push({ path: "/mendian/shouyin"})
        }
        if (res.code === 0) {
          await this.$http
            .get(
              "/cms/RightControl/GeRouleTreeListlogin",
              {
                CompanyId: res.data.model.companyInfo.id,
                RouleCode: res.data.model.roleInfo.projectCode,
                systemCode: 'websystem'
              }
            )
            .then((res) => {
              if (res.code == 0) {
                if (res.data.ids.length == 0) {
                  this.$message({
                    message: "没有登录系统查看的权限",
                    type: "error",
                  });
                  return;
                } else {
                  const info = res.data.list;
                  //循环对比 返回的路由结构  如果没有就删除已写的静态路由结构
                  for (
                    let menuIndex = 0;
                    menuIndex < this.menuListRoute.length;
                    menuIndex++
                  ) {
                    const menuList = this.menuListRoute[menuIndex];
                    const infoIndex = info.findIndex(
                      (infoItem) => menuList.code == infoItem.value
                    );
                    if (infoIndex == -1) {
                      this.menuListRoute.splice(menuIndex, 1);
                      menuIndex--;
                      continue;
                    }
                    //判断有二级菜单的进入
                    if (infoIndex != -1 &&info[infoIndex].menuType == 1 &&menuList.children&& menuList.children.length>0) {
                      for (
                        let menuListchid2Index = 0;
                        menuListchid2Index < menuList.children.length;
                        menuListchid2Index++
                      ) {
                        const menuListchid2 = menuList.children[menuListchid2Index];
                        const infoIndex2 = info[infoIndex].itemList.findIndex(
                          (infoChilds2) => {
                            const childrenCode = menuListchid2.code;
                            return childrenCode == infoChilds2.value;
                          }
                        );
                        if (infoIndex2 == -1) {
                          this.menuListRoute[menuIndex].children.splice(
                            menuListchid2Index,
                            1
                          );
                          menuListchid2Index--;
                        } else {
                          //判断必须childs不为空 并且写死的路由不为空才进入 否则就直接把这个菜单为空
                          if (
                            info[infoIndex].itemList[infoIndex2].itemList &&menuListchid2.children != ""
                          ) {
                            const Obj = [];
                            for (const key in menuListchid2.children) {
                              const code =menuListchid2.children[key].code;
                              const infoChildBtn =info[infoIndex].itemList[infoIndex2].itemList;
                              const infoIndexBtn = infoChildBtn.findIndex(
                                (infoChildBtn) => code == infoChildBtn.value
                              );
                              if (infoIndexBtn != -1) {
                                Obj[key] = menuListchid2.children[key];
                              }
                            }
                            menuListchid2.children = Obj;
                          } else {
                            menuListchid2.children && !info[infoIndex].itemList
                              ? (menuListchid2.children = [])
                              : "";
                          }
                        }
                      }
                    } else if (
                      menuList.children &&
                     menuList.children != ""
                    ) {
                      //没有二级菜单的直接进入btn按钮权限判断对比
                      const Obj = [];
                      for (const key in menuList.children) {
                        const code =menuList.children[key].code;
                        const infoIndexBtn = info[infoIndex].itemList.findIndex(
                          (infoChilds2) => code == infoChilds2.value
                        );

                        if (infoIndexBtn != -1) {
                          Obj[key] = menuList.children[key];
                        }
                      }
                      menuList.children = Obj;
                    }
                  }
                 
                  sessionStorage.setItem(
                    "menuListRoute",
                    JSON.stringify(this.menuListRoute)
                  );
                  console.log(this.menuListRoute, "this.menuListRoute");
                  if (this.menuListRoute[0].path) {
                    let path1 = this.menuListRoute[0].path;
                    this.$router.replace({ path: path1 });
                  } else {
                    let path1 = this.menuListRoute[0].children[0].path;
                    this.$router.replace({ path: path1 });
                  }
                }
              }
            });
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.loginPage {
  width: 100%;
  height: 100%;
  background: url(../../assets/img/login/loginBg.png) no-repeat;
  background-size: 100% 100%;
  display: flex;

  .loginL {
    width: 100%;
    position: relative;

    .loginMain {
      position: absolute;
      width: 629px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .loginR {
    width: 100%;
    position: relative;

    .loginMain1 {
      position: absolute;
      width: 62px;
      text-align: center;
      left: calc(50% - 230px);
      top: calc(50% - 305px);
      transform: translate(-50%, -50%);
    }

    .loginPanel {
      position: absolute;
      width: 490px;
      height: 640px;
      border-radius: 36px;
      background-color: rgba(255, 255, 255, .77);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      .loginHeadPic {
        width: 108px;
        height: 108px;
        margin: 84px auto 30px;
        display: block;
        border-radius: 50%;
      }

      .loginTitle {
        text-align: center;
        font-size: 40px;
        font-weight: 700;
      }

      // .text{
      // 	color: red;
      // 	font-size: 13px;
      // 	text-align: left;
      // 	margin:0px 36px 10px;
      // }
      .inputLine {
        border-bottom: 1px solid #D7D7D7;
        margin: 50px 36px 10px;

        .loginIcon {
          width: 32px;
          height: 32px;
          vertical-align: middle;
          padding-left: 5px;
        }

        ::v-deep .el-input {
          width: calc(100% - 46px);

          .el-input__inner {
            background-color: transparent;
            border: 0;
            font-size: 20px;
          }
        }
      }

      .btnLine {
        display: flex;
        justify-content: space-around;
        padding: 30px 35px;

        .submit {
          width: 40%;
          background-color: $mainColor;
          border-radius: 38px;
          text-align: center;
          cursor: pointer;
          color: #fff;
          line-height: 50px;
          font-size: 20px;
        }
        .submit1 {
          width: 40%;
          background-color: rgb(112, 109, 109,.6);
          border-radius: 38px;
          text-align: center;
          cursor: pointer;
          color: #fff;
          line-height: 50px;
          font-size: 20px;
        }
      }
    }
  }
}
</style>
